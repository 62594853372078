import request from '@/utils/request'

export function querySmsAuthList(params) {
  return request({
    url: `/icbc/smslAuth/list`,
    method: 'get',
    params
  })
}

export function addSmsAuth(data) {
  return request({
    url: `/icbc/smsAuth`,
    method: 'post',
    data
  })
}

export function cancelSmsAuth(data) {
  return request({
    url: `/icbc/smsCancelAuth`,
    method: 'post',
    data
  })
}
