<template>
  <div class="container">
    <div class="nav">
      <div class="nav_title">授权</div>
      <div class="nav_left_btn" @click="toHome">
        <img :src="cancel" alt width="15" height="25" />
      </div>
      <!--      <div class="nav_right_btn">下一页</div>-->
    </div>
    <div class="content">
      <div style="text-align: center">
        <div class="add" @click="toAddSmsAuth">添加授权</div>
        <!--      v-if="list && list.length > 0"-->
      </div>
      <div v-if="list && list.length > 0">
        <div class="reverse">
          <div v-for="(item, index) in list" :key="index" class="dish-card">
            <div class="card">
              <div class="eat-time">
                <div>{{ item.name }}</div>
                <div>
                  <span @click="onSubmit(item.id)">取消授权</span>
                </div>
              </div>
              <div class="eat-time">
                <div>号码</div>
                <div>{{ item.phone }}</div>
              </div>
              <div class="eat-time">
                <div>卡号</div>
                <div>{{ item.cardno }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-empty v-else description="暂无数据" style="padding-top: 40%" />
    </div>
    <van-overlay :show="show">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { querySmsAuthList } from "@/api/notice";
import { cancelSmsAuth } from "@/api/notice";

export default {
  name: "Notice",
  data() {
    return {
      cancel,
      num: "",
      show: false,
      list: [],
    };
  },
  created() {
    const { fetch } = this;
    fetch();
  },
  methods: {
    toHome() {
      this.$router.back();
    },
    toAddSmsAuth() {
      this.$router.push({ name: "SmsAuthAdd" });
    },
    async fetch() {
      const res = await querySmsAuthList();
      const { code, data } = res;
      if (code === 0) {
        this.list = data;
      }
    },
    async onSubmit(id) {
      const { fetch } = this;
      const res = await cancelSmsAuth({ id });
      const { code } = res;
      if (code === 0) {
        fetch();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  & > .nav {
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 49px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 9px 5px 9px 7px;
      border-color: transparent;
      background-size: 12px 1.25rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 4px 0 15px;
      top: 9px;
      left: 0;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }

  .content {
    padding: 4rem 1rem 0 1rem;
    .add {
      align-self: center;
      text-align: center;
      width: 30%;
      background-color: #f56c6c;
      color: #fff;
      display: inline-block;
      padding: 0.4rem 2rem;
      border-radius: 3rem;
      margin-bottom: 1rem;
    }
    .reverse {
      .dish-card {
        padding-bottom: 1.5rem;
      }
    }
    .title {
      display: flex;
      align-items: center;
      //padding: 0.5rem 1.5rem .4rem;
      padding-bottom: 0.5rem;
      text-align: center;
      & > .section {
        display: flex;
        justify-content: flex-end;
        span {
          padding: 0.1rem;
          border: 1px solid #1989fa;
          color: #1989fa;
          border-radius: 50%;
          font-size: 0.875rem;
          background-color: #fff;
          margin-left: 2rem;
          width: 1.3rem;
          height: 1.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      div {
        width: 33.3%;
        font-weight: 600;
      }
    }
    .card {
      background-color: #fff;
      border-radius: 0.625rem;
      padding: 0.625rem 1rem;
      box-shadow: 0 0 0.875rem #dcdcdc;
      .cancel-end {
        display: flex;
        justify-content: flex-end !important;
        padding-top: 0 !important;
      }
      & > .dishes {
        border-bottom: 1px dashed #ccc;
        .dish {
          padding: 0.5rem 0;
          justify-content: space-between;
          .common-center {
            display: flex;
            align-items: center;
          }
        }
        //display: flex;
      }
      & > .eat-time {
        border-bottom: 1px dashed #ccc;
        padding: 0.5rem 0;
        //color: #07c160;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .footer {
        padding-top: 0.5rem;
        //border-top: 1px dashed #ccc;
        ::v-deep .cancel {
          font-size: 0.875rem !important;
        }
        .btn {
          background-color: #ff9460;
          color: #fff;
          padding: 0.3rem 1rem;
          border-radius: 0.3rem;
          font-size: 0.875rem;
          font-weight: normal;
          display: inline-block;
        }
      }
    }
    .show-qs {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.common-flex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
::v-deep .van-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7);
}
</style>
